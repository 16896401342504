import React from 'react'
import { FieldWidth, IAddressInputFieldProps, useAddressFieldComponent } from 'olo-feature-address'
import { ValidationTextField } from '@dominos/components/inputs/validation-text-field/validation-text-field'

export const AddressField = (props: IAddressInputFieldProps) => {
  const {
    inputWidth,
    initialValue,
    addressAttributeName,
    attributeLabel,
    fieldStatus,
    fieldValidationFunctions,
    testId,
    onChange,
  } = useAddressFieldComponent(props)

  const onFiledChange = (updatedField: { [K: string]: ValidationField }) => {
    const validationField = updatedField[addressAttributeName]
    onChange({
      attributeName: addressAttributeName,
      value: validationField.value ?? '',
      isValid: validationField.isValid,
    })
  }

  if (fieldStatus == 'hide') return <></>

  const style = getStyles(inputWidth)

  return (
    <ValidationTextField
      testID={testId}
      fieldName={addressAttributeName}
      placeholder={attributeLabel}
      hasClearInputButton={fieldStatus !== 'disabled'}
      hideEmptyErrorContainer
      onChange={onFiledChange}
      validationRules={fieldValidationFunctions}
      initialValue={initialValue}
      allowInitialValueResetWhenUndefined={true}
      style={style.textFieldContainer}
      showAsteriskWhenRequired={false}
      disabled={fieldStatus == 'disabled'}
    />
  )
}

interface Style {
  textFieldContainer: React.CSSProperties
}

const getStyles = (inputWidth: FieldWidth): Style => ({
  textFieldContainer: {
    display: 'flex',
    flexDirection: 'column',
    flex: getWidthStyle(inputWidth),
  },
})

const getWidthStyle = (inputWidth: FieldWidth) => {
  switch (inputWidth) {
    case 'Full':
      return '0 0 100%'
    case 'Half':
      return '0 0 49%'
    case 'TwoThirds':
      return '0 0 65%'
    case 'Third':
      return '0 0 32%'
  }
}
